import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  RSA_PARAMETER,
  TokenInterceptor,
  VerifySignInterceptor,
} from '@deuna/bp-sh-helpers-ng';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './modules/empty-route/empty-route.component';
import { ApiExternalService } from './services/api-external.service';
@NgModule({
  declarations: [AppComponent, EmptyRouteComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    ApiExternalService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VerifySignInterceptor,
      multi: true,
    },
    {
      provide: RSA_PARAMETER,
      useValue: localStorage.getItem("RSA_PARAMETER"),
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
