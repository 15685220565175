import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataMerchant } from './api-external.types';

@Injectable()
export class ApiExternalService {
  constructor(private http: HttpClient) {}
  async getMerchantInfo():Promise<DataMerchant>{
    const observable: Observable<DataMerchant> = this.http.post<any>(
      `${environment.backend}/auth/profile`,null
    );
    return new Promise((resolve, reject) => {
      observable.subscribe({
        next: (data:DataMerchant ) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        },
      });
    })
  }
}
